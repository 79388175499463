export const getCustomOptions = (sourceTag, detectStyleEnabled, parentStyle) => {
    const CUSTOM_OPTIONS_WHITELIST = [
        "background-color",
        "font-color",
        "font-family",
        "font-size",
        "font-weight",
        "locale",
        "other-artists",
        "theme",
        "text-shadow",
        "text-transform",
        "track-button",
        "share-button",
        "country-filter",
        "rsvp",
        "request-show",
        "remind-me",
        "past-events",
        "past-events-offtour",
        "button-text-color",
        "button-bg-color",
    ];
    let optString = "";
    if (!sourceTag)
        return optString;
    CUSTOM_OPTIONS_WHITELIST.forEach((option) => {
        let value = sourceTag.getAttribute("data-" + option);
        if (!value && detectStyleEnabled && parentStyle) {
            const cssProperty = option === "font-color" ? "color" : option;
            value = parentStyle.getPropertyValue(cssProperty);
        }
        if (value)
            optString += "&" + option + "=" + encodeURIComponent(value);
    });
    return optString;
};
