import Connect from "./Connector";
import { getCustomOptions } from "./helpers/getCustomOptions";
const PRODUCTION_DOMAIN = "www.songkick.com";
const STAGING_DOMAIN = "skweb-iap.staging.soundbadger.net";
const DEV_DOMAIN = "dev.skweb-iap.staging.soundbadger.net";
// TODO: refactor once we have proper urls
const getRequestInformation = (songkickUrl) => {
    var _a, _b, _c, _d;
    const regex = new RegExp("^https?://(" +
        [PRODUCTION_DOMAIN, STAGING_DOMAIN, DEV_DOMAIN].join("|") +
        ")/(artists|users|venues)/([0-9a-zA-Z-+._]+|[0-9]+)$");
    const match = regex.exec(songkickUrl);
    if (match) {
        let targetDomain = "";
        switch (match[1]) {
            case PRODUCTION_DOMAIN:
                targetDomain = "widget-app.songkick.com";
                break;
            case STAGING_DOMAIN:
                targetDomain = "widget-app.staging.songkick.net";
                break;
            case DEV_DOMAIN:
                targetDomain = "localhost:3000";
                break;
        }
        let resourceType, resourceId = undefined;
        switch (match[2]) {
            case "venues":
                resourceType = "venue";
                resourceId = (_b = (_a = match[3]) === null || _a === void 0 ? void 0 : _a.match(/^\d+/)) === null || _b === void 0 ? void 0 : _b[0];
                break;
            case "users":
                resourceType = "username";
                resourceId = match[3];
                break;
            case "artists":
                resourceType = "artist";
                resourceId = (_d = (_c = match[3]) === null || _c === void 0 ? void 0 : _c.match(/^\d+/)) === null || _d === void 0 ? void 0 : _d[0];
                break;
        }
        const response = {
            targetDomain: targetDomain,
            // option to use Staging API in staging and development
            stagingEnv: !!targetDomain.match(/staging\.songkick\.net/),
            // eslint-disable-next-line no-useless-escape
            devEnv: !!targetDomain.match(/localhost\:3000/),
            resourceType,
            resourceId,
        };
        return response;
    }
    return null;
};
const getIframeURL = ({ sourceTag, parentStyle, detectStyleEnabled, }) => {
    var _a;
    if (!sourceTag)
        return;
    const requestInfo = getRequestInformation(sourceTag.href);
    if (!requestInfo)
        return;
    let widgetAnchorWidth = sourceTag.style.width;
    if (!widgetAnchorWidth)
        widgetAnchorWidth = "100%";
    let widgetAnchorText = sourceTag.textContent || sourceTag.innerText;
    if (!widgetAnchorText)
        widgetAnchorText = "";
    const resourceType = requestInfo.resourceType;
    const resourceId = (_a = requestInfo.resourceId) !== null && _a !== void 0 ? _a : "";
    const targetProtocol = requestInfo.devEnv ? "http:" : "https:";
    const targetDomain = requestInfo.targetDomain;
    let url = [
        targetProtocol,
        "//",
        targetDomain,
        "/?",
        // encodeURIComponent(resourceId),
        // "/songkick-widget.html?",
        resourceType,
        "=",
        encodeURIComponent(resourceId),
        "&header=",
        encodeURIComponent(widgetAnchorText),
        getCustomOptions(sourceTag, detectStyleEnabled, parentStyle),
    ].join("");
    // Update Later
    // option to use Staging API in staging and development
    if (requestInfo.stagingEnv) {
        url += "&staging=true";
        // url = url.replace(/net\/.*\/songkick-widget.html/, "net/")
    }
    else if (requestInfo.devEnv) {
        url += "&staging=true"; // Ensure we hit the staging API when in development
        url = url.replace(/3000/, "8080");
    }
    // url = url.replace(/com\/.*\/songkick-widget.html/, "com/")
    return url;
};
const getIframeId = (href) => {
    var _a;
    const resourceId = (_a = getRequestInformation(href)) === null || _a === void 0 ? void 0 : _a.resourceId;
    const uniqueId = `songkick-widget-${resourceId || ""}-${Math.floor(Math.random() * 10000 + 1)}`;
    return uniqueId;
};
const setUpIframe = ({ sourceTag, parentStyle, detectStyleEnabled }) => {
    var _a;
    const iframe = document.createElement("iframe");
    const url = (_a = getIframeURL({ sourceTag, parentStyle, detectStyleEnabled })) !== null && _a !== void 0 ? _a : "";
    const iframeId = getIframeId(sourceTag.href);
    iframe.setAttribute("id", iframeId);
    iframe.style.height = "400px";
    iframe.style.width = "100%";
    iframe.setAttribute("border", "none");
    iframe.setAttribute("frameBorder", "0");
    iframe.setAttribute("allow", "clipboard-write");
    // iframe.setAttribute(
    //   "sandbox",
    //   "allow-same-origin allow-scripts allow-forms allow-popups allow-modals allow-downloads allow-popups-to-escape-sandbox"
    // )
    iframe.src = url;
    const inject = () => {
        if (sourceTag === null || sourceTag === void 0 ? void 0 : sourceTag.parentNode) {
            // add iframe to dom
            sourceTag.parentNode.replaceChild(iframe, sourceTag);
            // connect iframe to window
            Connect(iframe, iframeId);
        }
    };
    inject();
};
const getParentStyle = (tag) => {
    let parentStyle = null;
    const detectStyleAttr = tag.getAttribute("data-detect-style");
    // old widget set detect style attr to 'true' rather than 'on'
    // me must handle both cases here
    const detectStyleEnabled = detectStyleAttr === "true" || detectStyleAttr === "on";
    if (detectStyleEnabled && tag.parentNode) {
        parentStyle = window.getComputedStyle(tag.parentNode);
    }
    return { parentStyle, detectStyleEnabled };
};
const init = () => {
    // block multiple of this script being run during execution
    if (window["isLoadingSKIFrame"])
        return null;
    window["isLoadingSKIFrame"] = true;
    const sourceTagList = document.querySelectorAll("a.songkick-widget");
    if (sourceTagList === null) {
        console.error("no a tag detected");
        return null;
    }
    sourceTagList.forEach((tag) => {
        const { parentStyle, detectStyleEnabled } = getParentStyle(tag);
        setUpIframe({ sourceTag: tag, parentStyle, detectStyleEnabled });
    });
    return reset();
};
// reset after all widgets added
// allows for script to be reloaded - fixes reload on TB preview
const reset = () => {
    window["isLoadingSKIFrame"] = false;
};
(() => {
    if (document.readyState === "complete")
        init();
})();
// wait until doc ready state gets set to complete
// this is normal synchronus script loading
// wait for the on load to ensure we capture all widgets on page
document.onreadystatechange = () => {
    if (document.readyState === "complete") {
        init();
    }
};
export default init;
