var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { connectToChild } from "penpal";
const Connect = (iframe, iframeId) => __awaiter(void 0, void 0, void 0, function* () {
    const methods = {
        setIframeHeight: (height) => {
            const iframeInDom = document.getElementById(iframeId);
            if (iframeInDom) {
                iframeInDom.style.height = `${height.toString()}px`;
            }
        },
    };
    const connection = connectToChild({
        // The iframe to which a connection should be made
        // Add debug: true to see error logs
        iframe,
        methods: Object.assign({}, methods),
    });
    try {
        const child = yield connection.promise;
        // fire any child methods here
        if (child === null || child === void 0 ? void 0 : child.setReferrer)
            child.setReferrer(window.location.origin);
    }
    catch (err) {
        console.log("Error on connection", err);
    }
});
export default Connect;
